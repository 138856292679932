import React from "react"
import styled from "@emotion/styled"
import {graphql} from "gatsby"
import Img from "gatsby-image"
import {Container, Row, Col} from "react-bootstrap"



import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const AboutPage = ({data}) => (
  <Layout>
    <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />
    <Content>
        <h1>about</h1>
        <Container fluid>
          <Row>
            <Col>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="me and my cat!"
              />
            </Col>
            <Col xs={7} sm={7}>
              <p>hi :) i'm jon <br></br>
                i enjoy solving complex problems in fast-paced environments.<br></br>
                i graduated uc berkeley spring 2019 with a b.a. in cognitive science.<br></br>
                i worked at <a href="https://www.samasource.com/">samasource</a> for over a year as a customer success engineer.<br></br>
                my next journey is at <a href="https://www.scale.com/">scale</a> as a field engineer.<br></br>
                find out more about me on my <a href="https://www.linkedin.com/in/jonathan-feng/">linkedin</a>.
                </p>
            </Col>
          </Row>
        </Container>
    </Content>
  </Layout>
)

export default AboutPage

export const pageQuery = graphql`
  query {
  file(relativePath: {eq: "me_and_cat.jpg"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
